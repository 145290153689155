import { useEffect, useState } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { useSocketIO } from "../components/SocketIO";
import { getVendorAdminServiceProxy } from "../services/VendorAdminServiceProxy";
import { vendorAdmin } from "@anything-pet/grpc-lib";

export function VendorHome() {
    const { vendorId } = useParams();
    const socket = useSocketIO();
    const [vendor, setVendor] = useState<vendorAdmin.VendorAdmin>();

    useEffect(() => {
        async function load() {
            if (vendorId) {
                const service = getVendorAdminServiceProxy(socket);

                const result = await service.getVendorAdmin({ id: vendorId});

                if (result.vendor) {
                    setVendor(result.vendor);
                }
            }
        }

        load();
    }, []);

    if (! vendorId) {
        return <Navigate to='/' />
    }

    if (! vendor) {
        return <h1>Loading ...</h1>
    }
    
    return (
        <>
            <h1>{vendor.code} - {vendor.name}</h1>
            <p>
                <Link to='edit'>Update vendor</Link>
            </p>
            <p>
                <Link to='staff'>Staffs</Link>
            </p>
        </>
    );
}
