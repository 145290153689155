export function getEnv(name: string, defaultValue: string | undefined = undefined ) {
    const result = process.env[name]

    if (!result) {
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        
        throw new Error(`Env [${name}] is not defined`);
    }

    return result;
}