/**
 * 
 * @param source 
 * @param changes 
 * @returns 
 */
export function applyChanges<TSource>(
    source : TSource, 
    changes : Partial<TSource> & { [k: string] : any }
    ) : TSource {

    const sourceMap : { [k: string] : unknown } = source as unknown as { [k: string] : unknown};

    const definedProps = Object.fromEntries(
        Object.entries(changes).filter(([k, v]) => v !== undefined)
    );

    const result = Object.assign(sourceMap, definedProps);
    
    return result as TSource;
}