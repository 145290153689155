import { useEffect, useState } from "react";
import { useSocketIO } from "../components/SocketIO"
import { staff } from "@anything-pet/grpc-lib";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";

export function UpdateStaff() {
    const { vendorId } = useParams()
    const { staffId } = useParams()

    const isNew = ! staffId;

    const [staffJson, setStaffJson] = useState<string>();

    const socket = useSocketIO();
    const navigate = useNavigate();

    useEffect(() => {
        async function load() {
            if (vendorId) {
                if (isNew) {
                    const newStaff : Omit<staff.Staff, 'id' |'vendorId'> = {
                        firstName: '',
                        lastName: '',
                        emailAddress: '',
                        position: ''
                    }
            
                    setStaffJson(JSON.stringify(newStaff, undefined, 2));
                } else {
                    const service = getStaffAdminServiceProxy(socket);

                    const result = await service.getStaffAdmin({ id: staffId, vendorId: vendorId});

                    if (result.staff) {
                        const { id, vendorId, ...staffParam } = result.staff;

                        setStaffJson(JSON.stringify(staffParam, undefined, 2));
                    }
                }
            }
        }

        load();
    }, []);

    if (! vendorId) {
        return <Navigate to='/user' />
    }

    if (! staffJson) {
        return <h1>Loading...</h1>
    }

    const onUpdate = async () => {
        const staff : staff.Staff = JSON.parse(staffJson);

        const service = getStaffAdminServiceProxy(socket);

        if (isNew) {
            await service.addStaffAdmin({ 
                staff: { 
                    ...staff, 
                    vendorId: vendorId 
                } 
            });
        } else {
            await service.updateStaffAdmin({ 
                staff:  { 
                    ...staff, 
                    id: staffId,
                    vendorId: vendorId 
                }  
            });
        }

        navigate(`/user/vendor/${vendorId}/staff`);
    }

    return (<>
        <h1>{(isNew) ? "New vendor": "Update"}</h1>
        <div>
            <textarea rows={10} cols={50} value={staffJson} onChange={evt => setStaffJson(evt.target.value)} />
            <div>
                <button onClick={onUpdate}>Save</button>
            </div>            
        </div>
    </>);
}