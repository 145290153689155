import { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useSocketIO } from "../components/SocketIO"
import { staffAdmin } from "@anything-pet/grpc-lib";
import { Link } from "react-router-dom";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";

export function StaffList() {
    const { vendorId } = useParams();

    const [staffs, setStaff] = useState<staffAdmin.StaffAdmin[]>();
    const socket = useSocketIO();

    useEffect(() => {
        async function load() {
            if (vendorId) {
                const service = getStaffAdminServiceProxy(socket);

                const result = await service.getStaffAdmins({ vendorId: vendorId });

                setStaff(result.staff);
            }
        }

        load();
    }, [vendorId]);

    if (! vendorId) {
        return <Navigate to='/user' />
    }

    if (! staffs) {
        return <h1>Loading...</h1>;
    }

    return (<>
        <h1>StaffAdmin list</h1>
        <Link to={`new`}>New</Link>
        <ul>
            {staffs.map(staff => {
                return (
                    <li key={staff.id}><Link to={`${staff.id}`}>{staff.firstName}, {staff.lastName}</Link></li>
                );
            })}
        </ul>
    </>);
}