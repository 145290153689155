import { getEnv } from "./args";

export class Env {
    #value: string | undefined;
    
    /**
     * 
     * @param name 
     */
    constructor(private name : string) { 
    }

    /**
     * 
     */
    get value() {
        if (this.#value === undefined) {
            this.#value = getEnv(this.name);
        }

        return this.#value;
    }
}