import { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from 'socket.io-client';
import { getEnv } from '@anything-pet/common-util'
import { AcquireTokenError, LogoutButton, useAuthManager } from "./Auth";
import { LinearProgress } from "@mui/material";

const socketApiUrl = getEnv("REACT_APP_SOCKETAPI_URL");

const context = createContext<Socket | null>(null);

interface ServerError {
    code: number,
    details: string
}

function connectAsync(socket: Socket) : Promise<void> {
    return new Promise((resolve, reject) => {
        let connected = false;

        const connectListener = () => {            
            connected = true;

            socket.offAny(connectErrorListener);

            resolve();
        };

        const connectErrorListener = (err : any) => {
            if (!connected) {
                socket.disconnect();

                socket.offAny(connectListener);

                reject(err);
            }
        };

        socket.once("connect", connectListener);
        socket.once("connect_error", connectErrorListener);
    
        socket.connect();
    });
}

/**
 * 
 * @param socket 
 */
function disconnectAsync(socket: Socket) : Promise<void> {
    return new Promise((resolve, reject) => {
        socket.once('disconnect', () => {
            resolve();
        });

        socket.disconnect();
    });
}

/**
 * Create Socket.io socket
 * @param url 
 * @param accessToken 
 * @returns 
 */
function createSocket(url: string, getAccessToken : () => Promise<string | undefined>): Socket {
    
    const socket = io(url, {
        auth: (callback => {
            getAccessToken()
            .then((accessToken) => callback({ token: accessToken }))
            .catch((err) => {
                console.log(`Error to acquire access Token: ${err?.message}`);

                callback({ token: '' })
            });
        }),
        transports: ['websocket', 'polling']
    });
    
    socket.on("disconnect", () => {
        console.log(`Disconnected: ${socket.id}`);                     
    });

    socket.on("connect", () => {
        console.log(`Connected: ${socket.id}`);
    });

    return socket
}

/**
 * 
 * @returns 
 */
export function useSocketIO() : Socket {
    const io = useContext(context);

    if (!io) {
        throw new Error("SocketIO is not read");
    }

    return io;
}

/**
 * 
 * @param obj 
 * @returns 
 */
function isServerError(obj : any) : obj is ServerError {
    return obj.code && obj.details;
}

/**
 * Helper function to send a request/response request to server as a promise
 * @param socket 
 * @param eventName 
 * @param request 
 * @returns 
 */
function socketEmit<TResult = any, TRequest = any>(socket: Socket, eventName: string, request: TRequest): Promise<TResult> {
    return new Promise<TResult>((resolve, reject) => {
        socket.emit(eventName, request, (result: TResult) => {
            if (isServerError(result)) {
                reject(result);
            } else {
                resolve(result);
            }
        })    
    });
}

/**
 * Helper function to send a request/response request to server as a promise
 * @param socket 
 * @param eventName 
 * @param args 
 */
export async function emitEvent<TResult = any, TRequest = any>(socket: Socket, eventName: string, request: TRequest): Promise<TResult> {
    try {
        return await socketEmit<TResult, TRequest>(socket, eventName, request);
    } catch (err) {
        if (isServerError(err) && err.code === 16 /* Unauthorized */) {            
            console.log(`Unauthorized.  Reconnect socket`);

            await disconnectAsync(socket);
            await connectAsync(socket);

            return await socketEmit<TResult, TRequest>(socket, eventName, request);
        } else {
            throw err;
        }
    }
}

/**
 * Helper function to subscribe an event
 * @param socket 
 * @param subscriptionId 
 * @param eventName 
 * @param callback 
 */
export function subscribeEvent<TMessage = any>(
    socket: Socket, subscriptionId: string, eventName: string, 
    callback: (message: TMessage) => void
) {
    socket.emit('subscribeEvent', subscriptionId, eventName);

    //  listen to event
    socket.on(eventName, callback);
}


export function SocketIOProvider(props: { scope?: string[], children: JSX.Element }) {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const authService = useAuthManager();
    const isLoading = ! authService;

    useEffect(() => {
        (async function() {        
            if (! isLoading) {
                try {
                    const result = createSocket(socketApiUrl, async () => {
                        let accessToken = undefined;

                        if (authService.isAuthenticated) {
                            const result = await authService.acquireTokenSilent({ 
                                scopes: props.scope 
                            });
    
                            accessToken = result.accessToken;
                        }

                        return accessToken;
                    });

                    await connectAsync(result);
                
                    setSocket(result);
                } catch (err) {
                    if (err instanceof AcquireTokenError) {
                        //  Silent logout
                        authService.logout();
                    } else {                
                        const error = err as Error;

                        setError(error)                    
                    }
                }
            }
        })();

        return () => {
            if (socket) {
                console.log(`Closing connection so ${socket.id}`);
                socket.close();
            }
        }
    }, [isLoading]);

    if (error) {
        return (
            <div>
                <p>{ `Error: ${error.message}` }</p>
                <LogoutButton />
            </div>
            );
    }

    if (!socket) {
        return <LinearProgress />
    }
    
    return (
        <context.Provider value={socket}>
            {props.children}
        </context.Provider>
    )
}
