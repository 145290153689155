import { staffAdmin } from '@anything-pet/grpc-lib'
import { Socket } from 'socket.io-client';
import { emitEvent } from '../components/SocketIO';


/**
 * 
 * @param socket 
 * @returns 
 */
export function getStaffAdminServiceProxy(socket : Socket) : staffAdmin.StaffAdminService {
    return new BffStaffAdminServiceProxy(socket);
}

/**
 * 
 */
class BffStaffAdminServiceProxy implements staffAdmin.StaffAdminService {
    /**
     * 
     * @param socket 
     */
    constructor(private socket : Socket) { }

    /**
     * 
     * @param request 
     */
    addStaffAdmin(request: staffAdmin.AddStaffAdminRequest): Promise<staffAdmin.AddStaffAdminResponse> {
        return emitEvent<staffAdmin.AddStaffAdminResponse, staffAdmin.AddStaffAdminRequest>(
            this.socket, "staffAdmin:addStaffAdmin", request
            );
    }

    /***
     * 
     */
    getStaffAdmins(request: staffAdmin.GetStaffAdminsRequest): Promise<staffAdmin.GetStaffAdminsResponse> {
        return emitEvent<staffAdmin.GetStaffAdminsResponse, staffAdmin.GetStaffAdminsRequest>(
            this.socket, "staffAdmin:getStaffAdmins", request
            );    
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    getStaffAdminsByOffering(request: staffAdmin.GetStaffAdminsByOfferingRequest): Promise<staffAdmin.GetStaffAdminsByOfferingResponse> {
        return emitEvent<staffAdmin.GetStaffAdminsByOfferingResponse, staffAdmin.GetStaffAdminsByOfferingRequest>(
            this.socket, "staffAdmin:getStaffAdminsByOffering", request
            );    
    }    

    /**
     * 
     * @param request 
     * @returns 
     */
    getStaffAdmin(request: staffAdmin.GetStaffAdminRequest): Promise<staffAdmin.GetStaffAdminResponse> {
        return emitEvent<staffAdmin.GetStaffAdminResponse, staffAdmin.GetStaffAdminRequest>(
            this.socket, "staffAdmin:getStaffAdmin", request
            );    
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    updateStaffAdmin(request: staffAdmin.UpdateStaffAdminRequest): Promise<staffAdmin.UpdateStaffAdminResponse> {
        return emitEvent<staffAdmin.UpdateStaffAdminResponse, staffAdmin.UpdateStaffAdminRequest>(
            this.socket, "staffAdmin:updateStaffAdmin", request
            );    
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    removeStaffAdmin(request: staffAdmin.RemoveStaffAdminRequest): Promise<staffAdmin.RemoveStaffAdminResponse> {
        return emitEvent<staffAdmin.RemoveStaffAdminResponse, staffAdmin.RemoveStaffAdminRequest>(
            this.socket, "staffAdmin:removeStaffAdmin", request
            );    
    } 

    /**
     * 
     * @param request 
     */
    setSupportedOfferings(request: staffAdmin.SetSupportedOfferingsRequest): Promise<staffAdmin.SetSupportedOfferingsResponse> {
        return emitEvent<staffAdmin.SetSupportedOfferingsResponse, staffAdmin.SetSupportedOfferingsRequest>(
            this.socket, "staffAdmin:setSupportedOfferings", request
            );    
    }

    /**
     * 
     * @param request 
     */
    getSupportedOfferings(request: staffAdmin.GetSupportedOfferingsRequest): Promise<staffAdmin.GetSupportedOfferingsResponse> {
        return emitEvent<staffAdmin.GetSupportedOfferingsResponse, staffAdmin.GetSupportedOfferingsRequest>(
            this.socket, "staffAdmin:getSupportedOfferings", request
            );    
    }    
}