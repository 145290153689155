import "./App.css";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import { AuthProvider, LoginCallback } from "./components/Auth";
import { NotFound } from "./pages/NotFound";
import { UserHome } from "./pages/UserHome";
import { AnonymousHome } from "./pages/AnonymousHome";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "./components/Auth";
import LogoutPage from "./pages/LogoutPage";

const link = document.createElement("link");
link.href =
    "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap";
link.rel = "stylesheet";
document.head.appendChild(link);

function App() {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <AuthenticatedTemplate>
                                    <Navigate to="/user" />
                                </AuthenticatedTemplate>
                                <UnauthenticatedTemplate>
                                    <AnonymousHome />
                                </UnauthenticatedTemplate>
                            </>
                        }
                    />
                    <Route path="/user/*" element={<UserHome />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/callback" element={<LoginCallback />} />
                    <Route path="*" element={<Navigate to="/not-found" />} />
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default App;
