import { vendorAdmin } from '@anything-pet/grpc-lib'
import { Socket } from 'socket.io-client';
import { emitEvent } from '../components/SocketIO';


type VendorAdminService = Pick<vendorAdmin.VendorAdminService, 'createVendorAdmin' | 'getVendorAdmins' | "getVendorAdmin" | "updateVendorAdmin">

/**
 * 
 * @param socket 
 * @returns 
 */
export function getVendorAdminServiceProxy(socket : Socket) : VendorAdminService {
    return new BffVendorAdminServiceProxy(socket);
}

/**
 * 
 */
class BffVendorAdminServiceProxy implements VendorAdminService {
    /**
     * 
     * @param socket 
     */
    constructor(private socket : Socket) { }

    /**
     * 
     * @param request 
     */
    createVendorAdmin(request: vendorAdmin.CreateVendorAdminRequest): Promise<vendorAdmin.CreateVendorAdminResponse> {
        return emitEvent<vendorAdmin.CreateVendorAdminResponse, vendorAdmin.CreateVendorAdminRequest>(
            this.socket, "vendorAdmin:createVendorAdmin", request
            );
    }

    /***
     * 
     */
    getVendorAdmins(request: vendorAdmin.GetVendorAdminsRequest): Promise<vendorAdmin.GetVendorAdminsResponse> {
        return emitEvent<vendorAdmin.GetVendorAdminsResponse, vendorAdmin.GetVendorAdminsRequest>(
            this.socket, "vendorAdmin:getVendorAdmins", request
            );    
        }

    /**
     * 
     * @param request 
     * @returns 
     */
    getVendorAdmin(request: vendorAdmin.GetVendorAdminRequest): Promise<vendorAdmin.GetVendorAdminResponse> {
        return emitEvent<vendorAdmin.GetVendorAdminResponse, vendorAdmin.GetVendorAdminRequest>(
            this.socket, "vendorAdmin:getVendorAdmin", request
            );    
        }

    /**
     * 
     * @param request 
     * @returns 
     */
    updateVendorAdmin(request: vendorAdmin.UpdateVendorAdminRequest): Promise<vendorAdmin.UpdateVendorAdminResponse> {
        return emitEvent<vendorAdmin.UpdateVendorAdminResponse, vendorAdmin.UpdateVendorAdminRequest>(
            this.socket, "vendorAdmin:updateVendorAdmin", request
            );    
        }
}