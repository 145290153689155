import { useEffect, useState } from "react";
import { useSocketIO } from "../components/SocketIO"
import { getVendorAdminServiceProxy } from "../services/VendorAdminServiceProxy";
import { vendorAdmin } from "@anything-pet/grpc-lib";
import { Link } from "react-router-dom";

export function VendorList() {
    const [vendors, setVendors] = useState<vendorAdmin.VendorAdmin[]>();
    const socket = useSocketIO();

    useEffect(() => {
        async function load() {
            const service = getVendorAdminServiceProxy(socket);

            const result = await service.getVendorAdmins({});

            setVendors(result.vendor);
        }

        load();
    }, []);

    if (! vendors) {
        return <h1>Loading...</h1>;
    }

    return (<>
    <h1>Vendor list</h1>
    <Link to='new'>New</Link>
    <ul>
        {vendors.map(vendor => {
            return (
                <li key={vendor.id}><Link to={`/user/vendor/${vendor.id}`}>{vendor.name}</Link></li>
            );
        })}
    </ul>
    </>);
}