import { LogoutButton, RequireAuthentication } from "../components/Auth"
import { SocketIOProvider } from "../components/SocketIO";
import { Route, Routes, Link } from "react-router-dom";
import { VendorList } from "./VendorList";
import { UpdateVendor } from "./UpdateVendor";
import { StaffList } from "./StaffList";
import { VendorHome } from "./VendorHome";
import { UpdateStaff } from "./UpdateStaff";

const apiScopes = ['https://anythingpet.onmicrosoft.com/admin-api/AdminAPI.Access'];

function UserMain() {
    return (
        <p>
            <Link to='vendor'>Vendors</Link>
        </p>
    )
}

export function UserHome() {
    return (
    <RequireAuthentication>
        <SocketIOProvider scope={apiScopes}>
            <>
                <LogoutButton />
                <Routes>
                    <Route index element={ <UserMain /> } />
                    <Route path="vendor" element={<VendorList/>} />
                    <Route path="vendor/new" element={<UpdateVendor/>} />
                    <Route path="vendor/:vendorId/edit" element={<UpdateVendor/>} />
                    <Route path="vendor/:vendorId" element={<VendorHome />} />
                    <Route path="vendor/:vendorId/staff" element={<StaffList />} />
                    <Route path="vendor/:vendorId/staff/new" element={<UpdateStaff />} />
                    <Route path="vendor/:vendorId/staff/:staffId" element={<UpdateStaff />} />
                </Routes>
            </>
        </SocketIOProvider>        
    </RequireAuthentication>
    );
}