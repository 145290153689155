import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc'
import * as tz from 'dayjs/plugin/timezone'
import { useSocketIO } from "../components/SocketIO"
import { getVendorAdminServiceProxy } from "../services/VendorAdminServiceProxy";
import { vendorAdmin } from "@anything-pet/grpc-lib";
import { useNavigate, useParams } from "react-router-dom";

dayjs.extend(utc.default);
dayjs.extend(tz.default);


export function UpdateVendor() {
    const { vendorId } = useParams()

    const isNew = ! vendorId;

    const [vendorJson, setVendorJson] = useState<string>();

    const socket = useSocketIO();
    const navigate = useNavigate();

    useEffect(() => {
        async function load() {
            if (isNew) {
                const newVendor : Omit<vendorAdmin.VendorAdmin, 'id'> = {
                    name: '',
                    code: '',
                    timeZone: dayjs.tz.guess(),
                    officeHours: {
                        sunday: undefined,
                        monday: { startTime: "09:00", endTime: "17:00" },
                        tuesday: { startTime: "09:00", endTime: "17:00" },
                        wednesday: { startTime: "09:00", endTime: "17:00" },
                        thursday: { startTime: "09:00", endTime: "17:00" },
                        friday: { startTime: "09:00", endTime: "17:00" },
                        saturday: undefined
                    },
                    location: '',
                    address: '',
                    about: '',
                    additionalInfo: '',
                    googleMapSrc: '',
                    bookingCancellationPolicy: "",
                    additionalBookingNotes: "",
                    images: []
                }
        
                setVendorJson(JSON.stringify(newVendor, undefined, 2));
            } else {
                const service = getVendorAdminServiceProxy(socket);

                const result = await service.getVendorAdmin({ id: vendorId});

                if (result.vendor) {
                    const { id, ...vendorParam } = result.vendor;

                    setVendorJson(JSON.stringify(vendorParam, undefined, 2));
                }
            }
        }

        load();
    }, []);

    if (! vendorJson) {
        return <h1>Loading...</h1>
    }

    const onUpdate = async () => {
        const vendor : vendorAdmin.VendorAdmin = JSON.parse(vendorJson);

        const service = getVendorAdminServiceProxy(socket);

        if (isNew) {
            await service.createVendorAdmin({ vendor: vendor });
        } else {
            await service.updateVendorAdmin({ 
                vendor: {
                    ...vendor,
                    id: vendorId
                }
            });
        }

        navigate('/user');
    }

    return (<>
        <h1>{(isNew) ? "New vendor": "Update"}</h1>
        <div>
            <textarea rows={50} cols={100} value={vendorJson} onChange={evt => setVendorJson(evt.target.value)} />
            <div>
                <button onClick={onUpdate}>Save</button>
            </div>            
        </div>
    </>);
}